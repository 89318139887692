import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, IonSelect, IonContent } from '@ionic/angular';
import { NotificationsPage } from 'src/app/notifications/notifications.page';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { globalVariables } from '../global_variables';
import { UserDetailsService } from '../shared_services/services/user_details.service';
import { ToastService } from '../shared_services/services/toast.service';
import { UserPermissionsService } from '../shared_services/services/user_permissions.service';

@Component({
  selector: 'app-navbar',
  templateUrl: 'navbar.page.html',
  styleUrls: ['navbar.page.scss']
})
export class NavbarPage implements OnInit {
  notifications: any = {};
  build: any = {};
  notificationsUnread: any = [];
  mobileMenu: any = 0;
  id: any = 0;
  failedConnections: any = 0;
  messageList: any = [];
  messagesUnread: any = [];

  constructor(
    private  router:  Router,
    public userDetailsService: UserDetailsService,
    public userPermissionsService: UserPermissionsService,
    private http: HttpClient, 
    public modalCtrl: ModalController,
    private toastService: ToastService
  ) { }

  navbarLink(x) {
    switch (x) {
      case 1:
        this.router.navigateByUrl('home');
        break;
      case 2:
        this.router.navigateByUrl('why');
        break;
      case 3:
        this.router.navigateByUrl('about');
        break;
      case 4:
        this.router.navigateByUrl('business');
        break;
      case 5:
        this.router.navigateByUrl('login');
        break;
      case 6:
        this.router.navigateByUrl('register');
        break;
      default:
        this.router.navigateByUrl('home');
    }
  }

  goAdmin() {
    this.router.navigateByUrl('admin');
  }

  goDashboardExtras() {
    this.router.navigateByUrl('dashboard/home-extra');
  }

  goDashboardHome() {
    this.router.navigateByUrl('dashboard/home');
  }

  goMessaging() {
    this.router.navigateByUrl('dashboard/messaging');
  }

  goProfile() {
    if (this.router.url == '/dashboard/home') {
      this.router.navigateByUrl('profile', { skipLocationChange: true }).then(() => {
        this.router.navigate(['dashboard/home'], { state: { tab: 9 } });
      });
    } 
    else {
      this.router.navigate(['dashboard/home'], { state: { tab: 9 } });
    }
  }

  logOut() {

    const header = {
      headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.userDetailsService.data.user.token}`)
    };

    const body = {
      email: this.userDetailsService.data.user.email,
      password: this.userDetailsService.data.user.password,
    };

    this.http
      .post<any>(globalVariables.apiUrl + 'auth/logout', body, /*header*/ )
      .subscribe(
        (response: any) => {
          const userDetails = response;

          this.toastService.showToast({header: userDetails.statusText, message: userDetails.message});
          this.userDetailsService.setData(null);

          window.localStorage.removeItem('userDetailsService');
          this.userDetailsService.data = null;

          this.router.navigateByUrl('home');
        },
        (error: any) => {
          const errorDetails = error;

          // error always triggered - ignore for now
          // this.toastService.showToast({header: errorDetails.statusText, message: errorDetails.message});

          this.userDetailsService.setData(null);
          window.localStorage.removeItem('userDetailsService');
          this.userDetailsService.data = null;

          this.router.navigateByUrl('home');
        }
      );
  }

  getMessages(type) {

    const param = new HttpParams()
      .set('recipientId', this.userDetailsService.data.user.userId)
      .set('limit', 20)
      .set('offset', 0)
      .set('type', type);

    this.http
      .get<any>(globalVariables.apiUrl + 'messaging/all', { observe: 'response', params: param })
      .subscribe(
        (response: any) => {
          this.messageList = response.body.messages;
          this.messagesUnread = [];
          for (var i = 0; i < this.messageList.length; i++) {
            if (this.messageList[i].opened == 0) {
              this.messagesUnread.push(this.messageList[i]);
            }
          }
        },
        (error: any) => {
          this.messageList = null;
          this.messagesUnread = [];
        }
      );
  }

  getNotifications() {

    const param = new HttpParams()
      .set('userId', this.userDetailsService.data.user.userId)

    this.http
      .get<any>(globalVariables.apiUrl + 'notification', { observe: 'response', params: param /*, headers: header*/ })
      .subscribe(
        (response: any) => {
          this.notifications = response.body;
          this.notificationsUnread = [];
          //this.notifications = [{ haveRead: 0}];
          if ((this.userDetailsService.data.user.storageCapacity / this.userDetailsService.data.user.storageUsage) >= 90) {
            this.notifications.push({ title: "System", due: "", recordType: "system", detail: "Utilisation threshold is at 90%+", haveRead: 0 });
          }
          for (var i = 0; i < this.notifications.length; i++) {
            if (this.notifications[i].haveRead == 0) {
              this.notificationsUnread.push(this.notifications[i]);
            }
          }
          window.localStorage.removeItem('notifications');
          this.failedConnections = 0;
        },
        (error: any) => {
          this.notifications = [];
          this.notificationsUnread = [];
          if ((this.userDetailsService.data.user.storageCapacity / this.userDetailsService.data.user.storageUsage) >= 90) {
            this.notifications.push({ title: "System", due: "", recordType: "system", detail: "Utilisation threshold is at 90%+", haveRead: 0 });
          }
          for (var i = 0; i < this.notifications.length; i++) {
            if (this.notifications[i].haveRead == 0) {
              this.notificationsUnread.push(this.notifications[i]);
            }
          }
          window.localStorage.removeItem('notifications');
          if (error.status == 502) {
            this.failedConnections ++;
          }
        }
      );
  }

  getTasks() {

    const param = new HttpParams()
      .set('userId', this.userDetailsService.data.user.userId)

    this.http
      .get<any>(globalVariables.apiUrl + 'notification/tasks', { observe: 'response', params: param /*, headers: header*/ })
      .subscribe(
        (response: any) => {
          this.notifications = response.body;
        },
        (error: any) => {
          this.notifications = [];
        }
      );
  }
  
  async openNotifications() {
    window.localStorage.setItem('notifications', JSON.stringify(this.notifications));
    const modal = await this.modalCtrl.create({
      component: NotificationsPage,
      backdropDismiss: true,
      componentProps: {
        name: 'Notifications'
      },
      cssClass: 'modal_notifications',
    });

    modal.onDidDismiss().then(data => {
      if (data.data != null && data.data.state) {
        this.getTasks();
      } else {
        return;
      }
    });
    return await modal.present();
  }

  getUserPermissions() {

    this.http
      .get(globalVariables.apiUrl + 'users/permissions', {observe: 'response'} )
      .subscribe(
        (response: any) => {

          window.localStorage.setItem('userPermissionsService', JSON.stringify(response.body));

          this.userPermissionsService.setData(response.body);
          window.localStorage.setItem('userPermissionsService', JSON.stringify({
            data: response.body,
          }));
        },
        (error: any) => {

          this.userPermissionsService.setData(null);
          window.localStorage.removeItem('userPermissionsService');
          this.userPermissionsService.data = null;
        }
      );
  }

  toggleMobileMenu() {
    this.mobileMenu =! this.mobileMenu;
  }


  ngOnInit() {
    // set globals
    this.build = globalVariables;

    //retrieve user details
    if (JSON.parse(window.localStorage.getItem('userDetailsService')) == null) {
      this.userDetailsService.setData(null);
      //this.router.navigateByUrl('home');
    } else {
      this.userDetailsService.setData(JSON.parse(window.localStorage.getItem('userDetailsService')).data);
    }

    //retrieve user permission details
    if (JSON.parse(window.localStorage.getItem('userPermissionsService')) == null) {
      this.getUserPermissions();
    } else {
      this.userPermissionsService.setData(JSON.parse(window.localStorage.getItem('userPermissionsService')).data);
    }

    this.getMessages('inbox');
    this.getNotifications();

    // check every 15 minutes - 900000
    // check every 1 minute - 60000
    this.id = setInterval(() => {
      this.getNotifications();
      this.getMessages('inbox');
    }, 900000);

  }

  openTab(x: any) {
    this.router.navigateByUrl('profile', { skipLocationChange: true }).then(() => {
      this.router.navigate(['dashboard/home'], { state: { tab: x } });
    });
    this.toggleMobileMenu()
  }

  ngOnDestroy() {
    if (this.id) {
      clearInterval(this.id);
    }
  }

}
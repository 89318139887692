import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NavbarLeftPage } from './navbar-left.page';

const routes: Routes = [
  {
    path: '',
    component: NavbarLeftPage,
  },
  {
    path: '',
    redirectTo: '/',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
})
export class NavbarLeftRoutingModule {}

import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { NavbarRoutingModule } from './navbar-routing.module';

import { NavbarPage } from './navbar.page';
import { HttpClientModule } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    FontAwesomeModule,
    NavbarRoutingModule
  ],
  exports: [
    NavbarPage
  ],
  declarations: [NavbarPage]
})
export class NavbarModule {}

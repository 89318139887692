import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { globalVariables } from '../global_variables';
import { ToastService } from '../shared_services/services/toast.service';
import { UserDetailsService } from '../shared_services/services/user_details.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.page.html',
  styleUrls: ['./notifications.page.scss'],
})
export class NotificationsPage implements OnInit {
  notifications: any = {};
  notificationsUnread: any = {};

  constructor(private router: Router, 
    public userDetailsService: UserDetailsService,
    private http: HttpClient, 
    private toastService: ToastService, 
    private modalCtr: ModalController) { }

  async close() {
    await this.modalCtr.dismiss();
    //this.getTasks();
  }

  getNotifications() {

    const param = new HttpParams()
      .set('userId', this.userDetailsService.data.user.userId)

    this.http
      .get<any>(globalVariables.apiUrl + 'notification', { observe: 'response', params: param /*, headers: header*/ })
      .subscribe(
        (response: any) => {
          this.notifications = response.body;
          this.notificationsUnread = [];
          if ((this.userDetailsService.data.user.storageCapacity / this.userDetailsService.data.user.storageUsage) >= 90) {
            this.notifications.push({ title: "System", due: "", recordType: "system", detail: "Utilisation threshold is at 90%+", haveRead: 0 });
          }
          for (var i = 0; i < this.notifications.length; i++) {
            if (this.notifications[i].haveRead == 0) {
              this.notificationsUnread.push(this.notifications[i]);
            }
          }
          window.localStorage.removeItem('notifications');

        },
        (error: any) => {
          this.notifications = [];
          this.notificationsUnread = [];
          if ((this.userDetailsService.data.user.storageCapacity / this.userDetailsService.data.user.storageUsage) >= 90) {
            this.notifications.push({ title: "System", due: "", recordType: "system", detail: "Utilisation threshold is at 90%+", haveRead: 0 });
          }
          for (var i = 0; i < this.notifications.length; i++) {
            if (this.notifications[i].haveRead == 0) {
              this.notificationsUnread.push(this.notifications[i]);
            }
          }
          window.localStorage.removeItem('notifications');
        }
      );
  }

  markAllRead() {

    const param = new HttpParams()
      .set('userId', this.userDetailsService.data.user.userId)

    const body = this.notifications;

    this.http
      .post<any>(globalVariables.apiUrl + 'notification/read', body, { observe: 'response', params: param /*, headers: header*/ })
      .subscribe(
        (response: any) => {
          window.location.reload();
        },
        (error: any) => {

        }
      );
  }

  deleteNotification(notification) {

    const param = new HttpParams()
      .set('notificationId', notification.notificationId)

    this.http
      .delete<any>(globalVariables.apiUrl + 'notification', { observe: 'response', params: param /*, headers: header*/ })
      .subscribe(
        (response: any) => {
          this.getNotifications();
          //this.close()
        },
        (error: any) => {

        }
      );
  }

  readNotification(notification) {

    const param = new HttpParams()
      .set('notificationId', notification.notificationId)

    const body = notification;
    body.haveRead = 1;

    this.http
      .patch<any>(globalVariables.apiUrl + 'notification', body, { observe: 'response', params: param /*, headers: header*/ })
      .subscribe(
        (response: any) => {
          this.getNotifications();
          //this.close()
        },
        (error: any) => {

        }
      );
  }

  completeNotificationTask(notification: any) {

    const param = new HttpParams()
      .set('notificationId', notification.notificationId)

    const body = notification;
    body.haveRead = 1;

    this.http
      .patch<any>(globalVariables.apiUrl + 'notification', body, { observe: 'response', params: param /*, headers: header*/ })
      .subscribe(
        (response: any) => {
          this.getNotifications();
        },
        (error: any) => {

        }
      );
  }

  test(test) {
    
  }

  ngOnInit() {
    this.notifications = JSON.parse(window.localStorage.getItem('notifications'));
  }

}

import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { NavbarLeftRoutingModule } from './navbar-left-routing.module';

import { NavbarLeftPage } from './navbar-left.page';
import { HttpClientModule } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    FontAwesomeModule,
    NavbarLeftRoutingModule
  ],
  exports: [
    NavbarLeftPage
  ],
  declarations: [NavbarLeftPage]
})
export class NavbarLeftModule {}

import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { NavbarRightRoutingModule } from './navbar-right-routing.module';

import { NavbarRightPage } from './navbar-right.page';
import { HttpClientModule } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    FontAwesomeModule,
    NavbarRightRoutingModule
  ],
  exports: [
    NavbarRightPage
  ],
  declarations: [NavbarRightPage]
})
export class NavbarRightModule {}

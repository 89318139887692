import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'business',
    loadChildren: () => import('./business/business.module').then( m => m.BusinessPageModule)
  },
  {
    path: 'profile-setup',
    loadChildren: () => import('./profile-setup/profile-setup.module').then( m => m.ProfileSetupPageModule)
  },
  {
    path: 'organization-profile-setup',
    loadChildren:
    () => import('./organization-profile-setup/organization-profile-setup.module').then( m => m.OrganizationProfileSetupPageModule)
  },
  {
    path: 'subscription-level',
    loadChildren: () => import('./subscription-level/subscription-level.module').then( m => m.SubscriptionLevelPageModule)
  },
  {
    path: 'payment',
    loadChildren: () => import('./payment/payment.module').then( m => m.PaymentPageModule)
  },
  {
    path: 'setup-password',
    loadChildren: () => import('./setup-password/setup-password.module').then( m => m.SetupPasswordPageModule)
  },
  {
    path: 'select-super-admin',
    loadChildren: () => import('./select-super-admin/select-super-admin.module').then( m => m.SelectSuperAdminPageModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./notifications/notifications.module').then( m => m.NotificationsPageModule)
  },
  {
    path: 'create-win-theme',
    loadChildren: () => import('./create-win-theme/create-win-theme.module').then( m => m.CreateWinThemePageModule)
  },
  {
    path: 'create-group',
    loadChildren: () => import('./create-group/create-group.module').then( m => m.CreateGroupPageModule)
  },
  {
    path: 'post-modal',
    loadChildren: () => import('./post-modal/post-modal.module').then( m => m.PostModalPageModule)
  },
  {
    path: 'verify-email',
    loadChildren: () => import('./verify-email/verify-email.module').then( m => m.VerifyEmailPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'create-content',
    loadChildren: () => import('./create-content/create-content.module').then( m => m.CreateContentPageModule)
  },
  {
    path: 'set-reminder',
    loadChildren: () => import('./set-reminder/set-reminder.module').then( m => m.SetReminderPageModule)
  },
  {
    path: 'set-content-activity',
    loadChildren: () => import('./set-content-activity/set-content-activity.module').then( m => m.SetContentActivityModule)
  },
  {
    path: 'users-list',
    loadChildren: () => import('./users-list/users-list.module').then( m => m.usersListPageModule)
  },
  {
    path: 'create-objective',
    loadChildren: () => import('./create-objective/create-objective.module').then( m => m.CreateObjectivePageModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then( m => m.AdminPageModule)
  },
  {
    path: 'update-group',
    loadChildren: () => import('./update-group/update-group.module').then( m => m.UpdateGroupPageModule)
  },
  {
    path: 'subscription',
    loadChildren: () => import('./subscription/subscription.module').then( m => m.SubscriptionPageModule)
  },
  {
    path: 'filter',
    loadChildren: () => import('./modal/filter/filter.module').then( m => m.FilterPageModule)
  },
  {
    path: 'win-themes',
    loadChildren: () => import('./content/win-themes/win-themes.module').then( m => m.WinThemesPageModule)
  },
  {
    path: 'content',
    loadChildren: () => import('./content/content/content.module').then( m => m.ContentPageModule)
  },
  {
    path: 'create-user-company',
    loadChildren: () => import('./create-user-company/create-user-company.module').then( m => m.CreateUserCompanyPageModule)
  },
  {
    path: 'delete-company-account',
    loadChildren: () => import('./delete-company-account/delete-company-account.module').then( m => m.DeleteCompanyAccountPageModule)
  },
  {
    path: 'delete-win-theme',
    loadChildren: () => import('./delete-win-theme/delete-win-theme.module').then( m => m.DeleteWinThemePageModule)
  },
  {
    path: 'deactivate-account',
    loadChildren: () => import('./deactivate-account/deactivate-account.module').then( m => m.DeactivateAccountPageModule)
  },  {
    path: 'helpbot',
    loadChildren: () => import('./helpbot/helpbot.module').then( m => m.HelpbotPageModule)
  },
  {
    path: 'welcome',
    loadChildren: () => import('./welcome/welcome.module').then( m => m.WelcomePageModule)
  },
  {
    path: 'edit-license',
    loadChildren: () => import('./edit-license/edit-license.module').then( m => m.EditLicensePageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'report-bug',
    loadChildren: () => import('./report-bug/report-bug.module').then( m => m.ReportBugPageModule)
  },
  {
    path: 'share-user',
    loadChildren: () => import('./share-user/share-user.module').then( m => m.ShareUserPageModule)
  },
  {
    path: 'send-question',
    loadChildren: () => import('./send-question/send-question.module').then( m => m.SendQuestionPageModule)
  },
  {
    path: 'create-reward',
    loadChildren: () => import('./create-reward/create-reward.module').then( m => m.CreateRewardPageModule)
  },
  {
    path: 'testimonial',
    loadChildren: () => import('./testimonial/testimonial.module').then( m => m.TestimonialPageModule)
  }


];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
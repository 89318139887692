import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, RouterModule } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { UserDetailsService } from './shared_services/services/user_details.service';
import { NavbarModule } from './navbar/navbar.module';
import { NavbarLeftModule } from './navbar-left/navbar-left.module';
import { NavbarRightModule } from './navbar-right/navbar-right.module';
import { ToastService } from './shared_services/services/toast.service';
import { LoadingService } from './shared_services/services/loading.service';

import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons'; //Solid icons
import { far } from '@fortawesome/free-regular-svg-icons'; // Regular icons
import { fab } from '@fortawesome/free-brands-svg-icons'; // Brand icons
import { UserPermissionsService } from './shared_services/services/user_permissions.service';
import { MessagingPage } from 'src/app/dashboard/messaging/messaging.page';

import { CKEditorModule } from 'ng2-ckeditor';

import { IonicSelectableModule } from 'ionic-selectable';
import { NgxEchartsModule } from 'ngx-echarts';

@NgModule({
  declarations: [
            AppComponent
          ],
  entryComponents: [],
  imports: [
            BrowserModule, 
            IonicModule.forRoot(), 
            AppRoutingModule, 
            NavbarModule, 
            NavbarLeftModule, 
            NavbarRightModule, 
            FontAwesomeModule, 
            CKEditorModule,
            IonicSelectableModule,
            NgxEchartsModule.forRoot({
              echarts: () => import('echarts'),
            }),
          ],
  providers: [
          { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, 
          UserDetailsService, 
          ToastService, 
          LoadingService,
          UserPermissionsService, 
          MessagingPage
        ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, far, fab);
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { globalVariables } from '../global_variables';
import { MessagingPage } from 'src/app/dashboard/messaging/messaging.page';
import { ToastService } from '../shared_services/services/toast.service';
import { UserDetailsService } from '../shared_services/services/user_details.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.page.html',
  styleUrls: ['./users-list.page.scss'],
})
export class usersListPage implements OnInit {
  userList: any = {};

  constructor(private router: Router, public userDetailsService: UserDetailsService,
    private http: HttpClient, private toastService: ToastService, private modalCtr: ModalController, public MessagingPage: MessagingPage) { }

  async close() {
    await this.modalCtr.dismiss();
  }

  selectUser(x) {
    window.localStorage.setItem('selectedUser', JSON.stringify(
      x,
    ));
    this.modalCtr.dismiss({ state: true });
    this.MessagingPage.selectRecipient(x)
  }

  ngOnInit() {
    this.userList = window.localStorage.getItem('userList');
    this.userList = JSON.parse(this.userList);
  }

}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoadingController } from '@ionic/angular';

@Injectable()
export class LoadingService {
  constructor(public loadingController: LoadingController) {}

  async presentLoading(duration: any) {
    const seconds = duration * 1000;
    const loading = await this.loadingController.create({
      cssClass: 'loading',
      message: 'Please wait...',
      duration: seconds
    });
    await loading.present();
  }

  async dismissLoading() {
    if (this.loadingController) {
      await this.loadingController.dismiss();
    }
  }

}
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { globalVariables } from '../global_variables';
import { UserDetailsService } from '../shared_services/services/user_details.service';
import { ToastService } from '../shared_services/services/toast.service';
import { UserPermissionsService } from '../shared_services/services/user_permissions.service';

@Component({
  selector: 'app-navbar-left',
  templateUrl: 'navbar-left.page.html',
  styleUrls: ['navbar-left.page.scss']
})
export class NavbarLeftPage implements OnInit {

  constructor(private  router:  Router, public userDetailsService: UserDetailsService,
    public userPermissionsService: UserPermissionsService,
    private http: HttpClient, private toastService: ToastService) { 

    }

    ngOnInit() {}

}

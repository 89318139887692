import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { ModalController, ToastController } from '@ionic/angular';
import { usersListPage } from 'src/app/users-list/users-list.page';
import { globalVariables } from 'src/app/global_variables';
import { FilterPage } from 'src/app/modal/filter/filter.page';
import { ToastService } from 'src/app/shared_services/services/toast.service';
import { UserDetailsService } from 'src/app/shared_services/services/user_details.service';

@Component({
  selector: 'app-messaging',
  templateUrl: './messaging.page.html',
  styleUrls: ['./messaging.page.scss'],
})
export class MessagingPage implements OnInit {
  login: any = {};
  info: any = {};
  replyStatus: any = {};
  reply: any = {};

  selectedType: any = {};
  messagesType: any = {};
  messageList: any = {};
  recipientList: any = {};
  hasRecipient: any = {};
  selectedRecipient: any = {};
  threadList: any = {};
  selectedThread: any = {};
  build: any = {};
  query: any = '';
  replyType: any = 'user';
  groupList: any = {};


  constructor(
    private router: Router, 
    public userDetailsService: UserDetailsService,
    private http: HttpClient, 
    private toastService: ToastService, 
    public modalCtrl: ModalController, 
    public toastCtrl: ToastController
  ) { }
  
    getGroups() {
      const param = new HttpParams()
      .set('companyName', this.userDetailsService.data.user.company)
      .set('limit', 20)
      .set('offset', 0)
      .set('query', '')
      .set('mine', 1)
      .set('userId', this.userDetailsService.data.user.userId)

    this.http
      .get<any>(globalVariables.apiUrl + 'groups/all', { observe: 'response', params: param /*, headers: header*/ })
      .subscribe(
        (response: any) => {
          this.groupList = response.body.groups.Items;
        },
        (error: any) => {
          this.groupList = null;
        }
      );
    }

  getMessages(type: any) {

    console.log(type);

    this.messagesType = type;

    const param = new HttpParams()
      .set('recipientId', this.userDetailsService.data.user.userId)
      .set('limit', 20)
      .set('offset', 0)
      .set('type', type);

    this.selectedType = type;

    this.http
      .get<any>(globalVariables.apiUrl + 'messaging/all', { observe: 'response', params: param })
      .subscribe(
        (response: any) => {
          if (type == 'groups') {
            this.messageList = this.groupList;
          }
          else {
            this.messageList = response.body.messages;
          }
        },
        (error: any) => {
          this.messageList = null;
        }
      );
  }

  selectThread(data: any) {
    
    if (this.messagesType == 'groups') {
      var threadId = data.groupId;
    }
    else {
      var threadId = data.threadId;
    }

    this.replyStatus = 0;
    this.reply = {};

    const param = new HttpParams()
      .set('threadId', threadId)
      .set('type', this.messagesType)

    this.http
      .get<any>(globalVariables.apiUrl + 'messaging/thread', { observe: 'response', params: param })
      .subscribe(
        (response: any) => {

          // set chat
          this.selectedThread = data;

          // set thread
          this.threadList = response.body.thread;

          // group chats
          if (this.messagesType == 'groups') {
            this.selectedThread.messageId = this.selectedThread.groupId;
            // existing group chat
            if (this.threadList.length > 0) {
              this.readMessage(this.selectedThread.groupId);
              // set chat heads
              if (this.threadList[0].senderId == this.userDetailsService.data.user.userId) {
                this.selectedThread.ourName = this.threadList[0].senderName;
                this.selectedThread.otherName = this.threadList[0].recipientName;
              }
              else {
                this.selectedThread.ourName = this.threadList[0].recipientName;
                this.selectedThread.otherName = this.threadList[0].senderName;
              }
            }
            // empty group chat
            else {

            }
          }
          // single recipient
          else {
            this.readMessage(this.selectedThread.messageId);
            // set chat heads
            if (this.threadList[0].senderId == this.userDetailsService.data.user.userId) {
              this.selectedThread.ourName = this.threadList[0].senderName;
              this.selectedThread.otherName = this.threadList[0].recipientName;
            }
            else {
              this.selectedThread.ourName = this.threadList[0].recipientName;
              this.selectedThread.otherName = this.threadList[0].senderName;
            }
          }
        },
        (error: any) => {
          this.threadList = null;
        }
      );
  }

  deleteThread(messageId) {

    this.toastCtrl.create({
      header: '',
      message: 'Are you sure?',
      buttons: [
        {
          text: 'Accept',
          role: 'accept',
          handler: () => {
            const param = new HttpParams()
            .set('messageId', messageId);
      
          this.http
            .delete<any>(globalVariables.apiUrl + 'messaging', { observe: 'response', params: param })
            .subscribe(
              (response: any) => {
                this.selectedThread = {};
                this.threadList = null;
                this.getMessages(this.selectedType);
              },
              (error: any) => {
              }
            );
          }
        },
        {
          text: 'Dismiss',
          role: 'cancel',
          handler: () => {
          }
        }
      ],
      color: 'success' ?? 'primary', // primary, success, warning, danger
      cssClass: 'bluj-toast',
      duration: 3000,
      position: 'top',
    }).then((toast) => {
      toast.present();
    });
  }

  readMessage(messageId) {

    const param = new HttpParams()
      .set('messageId', messageId)

      const body = this.selectedThread;
      body.opened = 1;

    this.http
      .patch<any>(globalVariables.apiUrl + 'messaging', body, { observe: 'response', params: param })
      .subscribe(
        (response: any) => {
          
        },
        (error: any) => {
          
        }
      );
  }

  compareTimeToday(timestamp) {
    return (new Date(timestamp).setHours(0, 0, 0, 0) == new Date().setHours(0, 0, 0, 0));
  }

  selectGroups(){
    if (this.replyType == 'user'){
      this.replyType = 'groups';
    } else{
      this.replyType = 'user';
    }
  }
  sendMessage() {

    let body = {};
    if(this.reply.groupId){

      this.reply.users = [];
      
      for (let i = 0; i < this.reply.groupLeaders.length; i++) {
        //this.reply.users.push({userId: this.reply.groupLeaders[i].userId});
        this.reply.users = this.reply.users.concat(this.reply.groupLeaders[i].userId) + ', ';
      }
      for (let i = 0; i < this.reply.groupMembers.length; i++) {
        //this.reply.users.push({userId: this.reply.groupMembers[i].userId});
        this.reply.users = this.reply.users.concat(this.reply.groupMembers[i].userId) + ', ';
      }

      body = {
        companyName: this.userDetailsService.data.user.company,
        message: this.reply.message,
        groupId: this.reply.groupId,
        groupUsers: this.reply.users,
        senderId: this.userDetailsService.data.user.userId,
        senderName: this.userDetailsService.data.user.firstName + ' ' + this.userDetailsService.data.user.lastName,
        recipientPhoto: this.reply.photo,
        recipientName: this.reply.groupName,
        //subject: this.reply.description,
        category: 'group',
        threadId: 0
      };

      this.http
      .post<any>(globalVariables.apiUrl + 'messaging', body, { observe: 'response'})
      .subscribe(
        (response: any) => {
          this.toastService.showToast({ header: 'Success', message: 'Message sent.' });
          this.reply = {};
          this.getMessages(this.selectedType);
        },
        (error: any) => {
          this.toastService.showToast({ header: error.statusText, message: 'Oh no, something has gone wrong.' });
        }
      );

    } else{

      body = {
        companyName: this.userDetailsService.data.user.company,
        senderId: this.userDetailsService.data.user.userId,
        senderPhoto: this.userDetailsService.data.user.photo,
        senderEmail: this.userDetailsService.data.user.email,
        senderName: this.userDetailsService.data.user.firstName + ' ' + this.userDetailsService.data.user.lastName,
        recipientId: this.reply.userId,
        recipientPhoto: this.reply.photo,
        recipientEmail: this.reply.email,
        recipientName: this.reply.firstName + ' ' + this.reply.lastName,
        subject: this.reply.subject,
        message: this.reply.message,
        threadId: 0
      };

      this.http
      .post<any>(globalVariables.apiUrl + 'messaging', body, { observe: 'response'})
      .subscribe(
        (response: any) => {
          this.toastService.showToast({ header: 'Success', message: 'Message sent.' });
          this.reply = {};
          this.getMessages(this.selectedType);
        },
        (error: any) => {
          this.toastService.showToast({ header: error.statusText, message: 'Oh no, something has gone wrong.' });
        }
      );
    }

  }

  replyMessage() {

    let sender = '';
    let receiverId = '';
    let recipientPhoto = '';
    let recipientEmail = '';
    let recipientName = '';
    let subject = '';
    let threadId = '';

    // sending a group message
    if (this.messagesType == 'groups') {
      sender = this.userDetailsService.data.user.userId;
      threadId = this.selectedThread.messageId;
    } else if (this.threadList[0].recipientId == this.userDetailsService.data.user.userId){
      sender = this.threadList[0].recipientId;
      receiverId = this.threadList[0].senderId;
      recipientPhoto = this.threadList[0].senderPhoto;
      recipientEmail = this.threadList[0].senderEmail;
      recipientName = this.threadList[0].senderName;
      subject = this.threadList[0].subject;
      threadId = this.threadList[0].threadId;
    } else {
      sender = this.threadList[0].senderId;
      receiverId = this.threadList[0].recipientId;
      recipientPhoto = this.threadList[0].recipientPhoto;
      recipientEmail = this.threadList[0].recipientEmail;
      recipientName = this.threadList[0].recipientName;
      subject = this.threadList[0].subject;
      threadId = this.threadList[0].threadId;
    }
    let body = {
      companyName: this.userDetailsService.data.user.company,
      senderId: sender,
      senderName: this.userDetailsService.data.user.firstName + ' ' + this.userDetailsService.data.user.lastName,
      recipientId: receiverId,
      recipientPhoto: recipientPhoto,
      recipientEmail: recipientEmail,
      recipientName: recipientName,
      subject: subject,
      message: this.reply.message,
      threadId: threadId,
    };

    this.http
      .post<any>(globalVariables.apiUrl + 'messaging', body, { observe: 'response'})
      .subscribe(
        (response: any) => {
          this.getMessages(this.selectedType);
          if (this.messagesType == 'groups') {
            this.selectThread(this.selectedThread);
          }
          else {
            this.selectThread(this.threadList[0]);
          }
        },
        (error: any) => {
          this.toastService.showToast({ header: error.statusText, message: 'Oh no, something has gone wrong.' });
        }
      );
  }

  searchMessages(query) {
    
    const param = new HttpParams()
    .set('userId', this.userDetailsService.data.user.userId)

    const body = {'query': query, type: this.selectedType};

    this.http
    .post<any>(globalVariables.apiUrl + 'messaging/search-all', body, { observe: 'response', params: param })
      .subscribe(
        (response: any) => {
          this.messageList = response.body.Items;
        },
        (error: any) => {
          this.toastService.showToast({ header: error.statusText, message: 'Oh no, something has gone wrong.' });
        }
      );
  }

  searchThread(thread, query) {
    
    const param = new HttpParams()
    .set('threadId', thread.threadId);

    const body = {'query': query};

    this.http
    .post<any>(globalVariables.apiUrl + 'messaging/search-thread', body, { observe: 'response', params: param })
      .subscribe(
        (response: any) => {
          if(query == ''){
            this.threadList = response.body.thread;
  
            if (this.threadList[0].senderId == this.userDetailsService.data.user.userId) {
              this.selectedThread.ourName = this.threadList[0].senderName;
              this.selectedThread.otherName = this.threadList[0].recipientName;
            }
            else {
              this.selectedThread.ourName = this.threadList[0].recipientName;
              this.selectedThread.otherName = this.threadList[0].senderName;
            }
          } else{

            this.threadList = response.body;
            //this.selectedThread = data;
  
            if (this.threadList[0].senderId == this.userDetailsService.data.user.userId) {
              this.selectedThread.ourName = this.threadList[0].senderName;
              this.selectedThread.otherName = this.threadList[0].recipientName;
            }
            else {
              this.selectedThread.ourName = this.threadList[0].recipientName;
              this.selectedThread.otherName = this.threadList[0].senderName;
            }
          }
        },
        (error: any) => {
          this.toastService.showToast({ header: error.statusText, message: 'Oh no, something has gone wrong.' });
        }
      );
  }

  async openRecipients() {
    const modal = await this.modalCtrl.create({
      component: usersListPage
    });
    return await modal.present();
  }

  getRecipients() {

    const param = new HttpParams()
    .set('company', this.userDetailsService.data.user.company)
    .set('limit', 20)
    .set('offset', 0)

    this.http
      .get<any>(globalVariables.apiUrl + 'messaging/recipients', { observe: 'response', params: param })
      .subscribe(
        (response: any) => {
          this.recipientList = response.body.recipients;
          this.recipientList.push({firstName: "Blu-J", lastName: "Support", userId: 1});
          if(this.userDetailsService.data.permissions.name == "BluJ Super Admin") {
            this.recipientList.push({firstName: "Super Admin", lastName: "- Send All", userId: 0});
          }
          window.localStorage.setItem('userList', JSON.stringify(
            this.recipientList,
          ));
        },
        (error: any) => {
          this.messageList = null;
        }
      );

  }

  selectRecipient(x) {
    this.selectedRecipient = x;
    this.hasRecipient = 1;
    alert('testing | selected user: ' + this.selectedRecipient.firstName + ' ' + this.selectedRecipient.lastName)
  }

  openReply() {
    if(this.replyStatus == 1){
      this.replyStatus = 0;
    }else{
      this.selectedType = 'sent';
      this.getMessages('sent');
      this.replyStatus = 1;
    }
  }

  returnZero() {
    return 0
  }

  ngOnInit() {
    this.build = globalVariables;
    this.replyStatus = 0;
    this.selectedType = 'inbox';
    this.getMessages(this.selectedType);
    this.getRecipients();
    this.getGroups();
  }

}